import { COST_JOB_LEVEL_ID, STATUS_MESSAGES, STORE_JOB_LEVEL_ID } from "src/app/GlobalConstants";
import { Login } from "src/app/login/login.model";

export const stringMerge = (value: string, ...args: string[]) => value.replace(/%s/gi, () => args.shift() || '');

export const getRequestStatus = (requestStatus: number, currentStatus = 0) => {
  if (currentStatus >= 0) {
    return STATUS_MESSAGES[requestStatus].status;
  } else if (currentStatus === COST_JOB_LEVEL_ID) {
    return 'In Cost';
  } else if (currentStatus === STORE_JOB_LEVEL_ID) {
    return 'In Store';
  }
  return '';
}

export const setAuthValue = (authFlag: string, value: Login): void => {
  localStorage.setItem(authFlag, JSON.stringify(value));
};

export const getAuthValue = (authFlag: string): Login => {
  return JSON.parse(localStorage.getItem(authFlag) || '{}');
};

export const removeNullValues = (obj: { [key: string]: any } | any[]): unknown => {
  if (Array.isArray(obj)) {
    return obj
      .map(value => (typeof value === 'object' ? removeNullValues(value) : value))
      .filter(value => value !== null && value !== undefined);
  } else if (typeof obj === 'object' && obj !== null) {
    const cleanedObject: any = {};
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (value !== null && value !== undefined) {
        cleanedObject[key] =
          typeof value === 'object' ? removeNullValues(value) : value;
      }
    });
    return cleanedObject;
  }
  return obj;
};
