import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { get, isEmpty } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICE_URL } from 'src/app/GlobalConstants';
import { ApiReloadStatus } from 'src/app/shared/includes/page-header/page-header.model';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { CompanyItem } from '../company/company.model';
import { CompanyService } from '../company/company.service';
import { CostCodeGrid, CostCodeRequest, CostCodeResponse, CostCodeResponseTime } from './cost-code.model';

const COST_CODE_REQUEST_URL = `${API_SERVICE_URL}/costcodeinformation`;

@Injectable({
  providedIn: 'root',
})
export class CostCodeService {
  private http = inject(HttpClient);
  private pageService = inject(PageHeaderService);
  private companyService = inject(CompanyService);

  private costCodeData: CostCodeResponse = {};
  costCodeCrumb$: BehaviorSubject<CostCodeRequest[]> = new BehaviorSubject<CostCodeRequest[]>([]);

  private spliceCrumbData(level: number = 1) {
    const data = [...this.costCodeCrumb$.value];
    data.splice(level - 1, data.length);
    return data;
  };

  private updateCrumbInformation(param: CostCodeRequest) {
    const { costCodeLevel = 1 } = param;
    const data: CostCodeRequest[] = this.spliceCrumbData(costCodeLevel);
    data.push(param);
    this.costCodeCrumb$.next(data);
  }

  getCostCode = (param: CostCodeRequest): Observable<CostCodeResponseTime> => {
    const {
      companyId,
      costCodeId = 0,
    } = param;
    if (!companyId) {
      return this.pageService.errorHelper('getCostCode: missing the required params.');
    }

    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'costcode');
    const existingValue = get(this.costCodeData, `[${companyId}][${costCodeId}]`);
    if (reloadStatus) {
      this.costCodeData = {};
    }
    if (!reloadStatus && !isEmpty(existingValue)) {
      this.updateCrumbInformation(param);
      return of(existingValue);
    }

    const costCodeListUrl = COST_CODE_REQUEST_URL + `/company/${companyId}/parent/${costCodeId}`;
    return this.http.get<CostCodeGrid[]>(costCodeListUrl)
      .pipe(
        map((response: CostCodeGrid[]) => {
          const revisedResponse: CostCodeResponseTime = {
            requestAsOf: new Date(),
            costCodeLists: response,
          }

          if (this.costCodeData[companyId]) {
            this.costCodeData[companyId][costCodeId] = revisedResponse;
          } else {
            this.costCodeData[companyId] = { [costCodeId]: revisedResponse };
          }
          this.updateReloadStatus({ costcode: false });
          this.updateCrumbInformation(param);
          return revisedResponse;
        })
      );
  }

  postCostCodeItem = (param: CostCodeGrid) => {
    const {
      companyId
    } = param;
    if (!companyId) {
      return this.pageService.errorHelper('postCostCode: missing the required params.');
    }
    const costCodeAddUrl = COST_CODE_REQUEST_URL + `/company/${companyId}`;
    return this.http.post(costCodeAddUrl, param);
  }

  putCostCodeItem = (param: CostCodeGrid) => {
    const {
      companyId,
      id,
    } = param;
    if (!companyId || !id) {
      return this.pageService.errorHelper('putCostCode: missing the required params.');
    }
    const costCodeEditUrl = COST_CODE_REQUEST_URL + `/company/${companyId}/id/${id}`;
    return this.http.post(costCodeEditUrl, param);
  }

  getCompanyDetail = (companyId: number): Observable<CompanyItem> => this.companyService.getCompanyDetail(companyId);

  updateReloadStatus = (param: ApiReloadStatus) => this.pageService.setApiReloadStatus(param);

  resetCache() {
    this.costCodeData = {};
    this.costCodeCrumb$.next([]);
  }
}
